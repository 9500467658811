/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { Avatar, Skeleton } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { WebServices } from 'src/services/requests';
import { fNumber } from 'src/utils/format-number';
import EmptyData from 'src/components/empty/emptyData';
import ChartRadialBar from 'src/components/chart/chart-view/chart-radial-bar';
// import ListItemDetailsChart from './list-item-details-chart';

export default function ListItemDetails({ movieId }) {
  const [resDropDown, setResDropDown] = useState(null);

  async function fetchDropDownData() {
    const { data } = await WebServices.getAllUserEstimateDetails({ MovieId: movieId });
    setResDropDown(data?.data);
  }

  useEffect(() => {
    fetchDropDownData({ MovieId: movieId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allScoresZero = _.every(resDropDown?.leaderBoards, { score: 0 });
  const sortedLeaderboards = resDropDown?.leaderBoards.sort((a, b) => a.rank - b.rank);
  // console.log('resDropDown?.leaderBoards : ', resDropDown?.leaderBoards);
  // console.log('sortedLeaderboards : ', sortedLeaderboards);
  const leaders = sortedLeaderboards?.length === 0 ? tempLeaderBoards : sortedLeaderboards;
  const ownUserItem = _.find(sortedLeaderboards, { isOwnUser: true });
  const ownUserRank = ownUserItem ? ownUserItem.rank : null;

  // console.log(resDropDown);

  return (
    <>
      {resDropDown && resDropDown?.estimateStats ? (
        <div className="container mx-auto">
          <div className="w-full flex justify-between p-5 pt-5 mt-8 border-t border-solid max-md:p-0 max-md:pt-2 border-gray-200 max-lg:flex-col">
            <div className="flex-1 max-lg:py-5  max-lg:border-b max-lg:border-solid max-lg:border-gray-200 ">
              <h2 className="text-xl font-semibold mb-5 text-black max-xl:text-lg">
                Seyirci Tahmininiz
              </h2>
              <div className="flex items-start max-md:flex-row max-md:justify-between max-md:items-center max-sm:flex-col max-sm:-mt-0 max-lg:-mt-0 max-sm:items-start max-md:-mt-16 max-lg:flex-col">
                <div className="flex flex-col gap-7 max-lg:gap-2">
                  <div className="flex flex-col gap-1 max-lg:gap-0">
                    <span className="text-sm text-gray-500 max-xl:text-xs">
                      Türkiye Seyirci Sayısı
                    </span>
                    <div className="text-2xl max-lg:text-lg font-bold max-xl:text-lg ">
                      {fNumber(resDropDown?.estimateStats?.boxOfficeAudience) || 0}
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 max-lg:gap-0">
                    <span className="text-sm text-gray-500 max-xl:text-xs">Biletinial Tahmini</span>
                    <div className="text-2xl max-lg:text-lg font-bold max-xl:text-lg">
                      {fNumber(resDropDown?.estimateStats?.biletinialEstimate) || 0}
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 max-lg:gap-0">
                    <span className="text-sm text-gray-500 max-xl:text-xs">Tahmininiz</span>
                    <div className="text-2xl max-lg:text-lg font-bold max-xl:text-lg">
                      {fNumber(resDropDown?.estimateStats?.userEstimate) || 0}
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 max-lg:gap-0">
                    <span className="text-sm text-gray-500 max-xl:text-xs">Başarı Yüzdeniz</span>
                    <div className="text-2xl max-lg:text-lg font-bold max-xl:text-lg ">
                      {resDropDown?.estimateStats?.userSuccessRate || 0}%
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 max-lg:gap-0">
                    <span className="text-sm text-gray-500 max-xl:text-xs">
                      Filmin Liderlik Tablosundaki Sıranız
                    </span>
                    <div className="text-2xl max-lg:text-lg font-bold max-xl:text-lg">
                      {ownUserRank || 0}
                    </div>
                  </div>
                </div>

                {/* <ListItemDetailsChart value={resDropDown?.estimateStats?.userSuccessRate || 0} /> */}
                <ChartRadialBar
                  series={[resDropDown?.estimateStats?.userSuccessRate || 0]}
                  labels={['Türkiye Seyirci Sayısına\nGöre Başarı Oranı']}
                  value={resDropDown?.estimateStats?.userSuccessRate || 0}
                />
              </div>
            </div>
            <div className="flex-1  max-lg:py-5 max-lg:border-b max-lg:border-solid max-lg:border-gray-200 ">
              <h2 className="text-xl font-semibold mb-5 text-black max-xl:text-lg">
                Sektörel Poziyon Başarı Oranı
              </h2>
              <div className="flex flex-col gap-7 max-lg:gap-2">
                {resDropDown?.userTypesRates?.map((item, index) => (
                  <div key={index} className="flex flex-col gap-1 max-lg:gap-0">
                    <span className="text-sm text-gray-500 max-xl:text-xs">
                      {item.userTypes} Başarı Oranı
                    </span>
                    <div className="text-2xl max-lg:text-lg font-bold max-xl:text-lg">
                      {item.rate.toFixed(2) || 0}%
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" max-lg:py-5">
              <h2 className="text-xl font-semibold mb-5 text-black max-xl:text-lg">
                Tahmin Liderlik Tablosu
              </h2>
              <div className="flex flex-col w-80  max-lg:w-full">
                {leaders?.map((item, index) => (
                  //   console.log(item);
                  <div
                    className="flex items-center justify-between last-of-type:border-none py-2 border-b border-solid border-gray-200"
                    key={index}
                  >
                    <div className="flex items-center">
                      <div
                        className={`min-w-[30px] font-bold text-${item?.isOwnUser && '[#0071E3]'}`}
                      >
                        {item?.rank}
                      </div>

                      <Avatar
                        className=" mr-3"
                        src={item?.profilePhotos}
                        width={40}
                        height={40}
                        alt={item?.userNameSurname}
                      />
                      <p
                        className={`font-semibold text-base text-${item?.isOwnUser && '[#0071E3]'}`}
                      >
                        {item?.isOwnUser ? 'Siz' : item?.userNameSurname}
                      </p>
                    </div>
                    {index === 0 && !allScoresZero && (
                      <Avatar
                        className=" mr-3 w-6 h-6"
                        src="/assets/extra/king.svg"
                        width={24}
                        height={24}
                        alt={item?.userNameSurname}
                      />
                    )}
                    <div />
                    <div className={`font-semibold~ text-${item?.isOwnUser && '[#0071E3]'}`}>
                      {item?.score}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : !resDropDown?.estimateStats?.boxOfficeAudience ? (
        <EmptyData text="Bu film için henüz analiz yapılmadı" small />
      ) : (
        <Skeleton width="100%" sx={{ mt: 3 }} height={120} />
      )}
    </>
  );
}

const tempLeaderBoards = [
  {
    rank: 1,
    userNameSurname: 'Kullanıcı 1',
    profilePhotos: '/assets/extra/user(temp).svg',
    score: '-',
    isOwnUser: false,
  },
  {
    rank: 2,
    userNameSurname: 'Kullanıcı 2',
    profilePhotos: '/assets/extra/user(temp).svg',
    score: '-',
    isOwnUser: false,
  },
  {
    rank: 3,
    userNameSurname: 'Kullanıcı 3',
    profilePhotos: '/assets/extra/user(temp).svg',
    score: '-',
    isOwnUser: false,
  },
];

// text-${item?.isOwnUser && '[#0071E3]'}
