/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Stack, Button, Avatar, useMediaQuery, Typography, useTheme } from '@mui/material';
import { WebServices } from 'src/services/requests';
import LoadingSection from 'src/components/loading-screen/loading-section';

export default function InfoDialog({ item }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [resDataInfo, setResDataInfo] = useState(null);

  // console.log('seçilen item : ', item);
  // console.log('resDataInfo : ', resDataInfo);

  const handleNewOpenWindow = (url) => {
    // Yeni sayfayı aç
    window.open(url, '_blank');
  };

  async function fetchResMovieDetail() {
    const { data } = await WebServices.getAllMyForecastEventDetail({
      MovieId: item?.movieId,
    });
    setResDataInfo(data?.data);
  }

  useEffect(() => {
    fetchResMovieDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {resDataInfo !== null ? (
        <Stack p={5} direction={isMobile ? 'column' : 'row'} spacing={5}>
          <Stack spacing={2.5}>
            <Avatar
              variant="rounded"
              src={resDataInfo?.imageUrl}
              alt=""
              sx={{ width: 130, height: 174 }}
            />
            {resDataInfo.trailerUrl && (
              <Stack>
                <Button
                  onClick={() => handleNewOpenWindow(resDataInfo?.trailerUrl)}
                  // disabled={!resDataInfo?.trailerUrl}
                  variant="outlined"
                  sx={{ gap: 1 }}
                >
                  <Avatar
                    variant="rounded"
                    src="/assets/extra/play_ic.svg"
                    alt=""
                    sx={{ width: 20, height: 20 }}
                  />
                  Fragman
                </Button>
              </Stack>
            )}
          </Stack>
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography variant="h4">{resDataInfo?.movieName}</Typography>
              <Stack direction="row" spacing={1}>
                {resDataInfo?.genres?.map((genre, index) => (
                  <Typography
                    variant="caption"
                    key={index}
                    sx={{
                      p: 1,
                      fontWeight: 500,
                      borderRadius: 0.5,
                      color: theme.palette.common.black,
                      background: theme.palette.grey[150],
                    }}
                  >
                    {genre?.toUpperCase()}
                  </Typography>
                ))}
              </Stack>
            </Stack>
            <Stack>
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.common.black,
                  fontWeight: 500,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '3',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {parse(resDataInfo?.description || '')}
              </Typography>
            </Stack>
            <Stack alignItems="flex-start">
              <Typography
                onClick={() => handleNewOpenWindow(resDataInfo?.biletinialUrl)}
                variant="body1"
                sx={{ color: theme.palette.primary[500], cursor: 'pointer' }}
              >
                Filmin Biletinial Sayfası →
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack minWidth={500}>
          <LoadingSection />
        </Stack>
      )}
    </>
  );
}
